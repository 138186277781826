#initialState-block {
  height: 100%;
  color: #6b727f;

  .top-block {
    position: relative;
    width: 100%;
    height: 50%;
    border-radius: 3px 3px 0px 0px;
    background-color: #9ba2af;
  }

  .bottom-block {
    height: 50%;
  }

  .information-icon-block {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin-top: 20px;
  }

  .information-icon {
    width: min-content;
    background-color: #9ba2af;
    margin: 0 auto;
    color: #6b727f;
    padding: 8px;
  }

  .information-icon-block p {
    color: #4d5664;
    font-weight: 600;
  }

  ul {
    list-style: none;
  }

  ul li {
    list-style: none;
    font-size: 1.2rem;
  }

  ul li.li-green::before {
    content: "●";
    color: #35d398;
    font-weight: bold;
    display: inline-block;
    width: 40px;
    margin-left: -1em;
    font-size: 2rem;
    line-height: 2rem;
    vertical-align: middle;
  }

  ul li.li-orange::before {
    content: "●";
    color: orange;
    font-weight: bold;
    display: inline-block;
    width: 40px;
    margin-left: -1em;
    font-size: 2rem;
    line-height: 2rem;
    vertical-align: middle;
  }

  ul li.li-red::before {
    content: "●";
    color: #ee4442;
    font-weight: bold;
    display: inline-block;
    width: 40px;
    margin-left: -1em;
    font-size: 2rem;
    line-height: 2rem;
    vertical-align: middle;
  }

  .logo-loading {
    img {
      -webkit-animation: spin 1s linear infinite;
      -moz-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }
    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}
