#redeemRedwards {
  background-color: #ca0538;
  min-height: 100vh;

  .main-block {
    margin-top: 8vw;
    height: 80%;
  }

  .block {
    height: 360px;
    min-width: 350px;
    max-width: 420px;
  }

  @media only screen and (min-width: 2000px) {
    .main-block {
      justify-content: center !important;

      .block {
        margin: 0 150px;
      }
    }
  }
}
