.bullets-block {
  div {
    display: flex;
    img {
      height: 1rem;
    }
    .bullet-text {
      margin-left: 1rem;
    }
  }
}
