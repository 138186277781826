#addPoints {
  .cardBox {
    &.storecard {
      .cardBox-head {
        &.terugkerend {
          background-color: #ffc781;
          // ul li {
          //   min-width: 90px;
          //   // &:first-child {
          //   //   margin-right: 30px;
          //   // }
          // }
        }
        &.new {
          background-color: #fea0ac;
        }
        &.addmember {
          min-height: auto;
        }
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        display: block;
        background-color: #b2d8d2;
        ul {
          max-width: 310px;
          padding-right: 10px;
          padding-left: 10px;
        }
        h1 {
          font-size: 33px;
          text-align: center;
        }
        .cardBox-head__alert {
          max-width: 340px;
          margin-right: auto;
          margin-left: auto;
          h2 {
            font-size: 23px;
          }
          h5 {
            font-size: 17px;
          }
        }
      }
      .cardBox-body {
        button {
          height: 65px;
          @media screen and (max-width: 375px) {
            font-size: 12px;
          }
        }
      }
      &.addpoints {
        ul {
          max-width: 230px;
        }
      }
    }

    overflow: hidden;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.16);
    width: calc(100% - 25px);
    max-width: 400px;
    margin: auto;
    margin-top: 5rem;
    border-radius: 15px;
    &-head {
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        font-size: 17px;
        font-weight: bold;
      }
      background: #f7f8fa;
    }
    &-body {
      background-color: #fff;
      ul {
        li {
          cursor: pointer;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          .shape {
            display: block;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            margin: auto;
            margin-bottom: 4px;
          }
          &.herkend .shape {
            background-color: #b2d8d2;
          }
          &.terugkerend .shape {
            background-color: #ffc781;
          }
          &.nieuw .shape {
            background-color: #fea0ac;
          }
        }
      }
      &__btns {
        text-align: center;
        .btn-main-bg {
          background-color: #1b1077 !important;
          color: #fff !important;
          border-radius: 15px !important;
          height: 49px;
          text-transform: uppercase;
          width: 150px;
          @media screen and (max-width: 375px) {
            width: calc(50% - 20px);
          }
          &:first-child {
            margin-right: 8px;
          }
        }

        .w-full {
          height: 50px !important;
          width: 100%;
          max-width: 310px;
          margin-left: auto;
          margin-right: auto;
          @media screen and (max-width: 375px) {
            max-width: calc(100% - 40px);
          }
        }
      }
      .send-sms {
        max-width: 310px;
        margin: auto;
      }
      &__search {
        width: 100%;
        max-width: 310px;
        @media screen and (max-width: 375px) {
          max-width: calc(100% - 40px);
        }
        margin: auto;

        background-color: #fff;
        .input-group {
          border-radius: 15px;
        }

        input,
        .input-group-text {
          height: 40px;
          border: 0;
          background-color: transparent;
        }
        input::placeholder,
        input::-ms-input-placeholder,
        input::-webkit-input-placeholder {
          color: #1b1077 !important;
          opacity: 1 !important;
        }
      }
    }

    .cardBox-body__search {
      .sms-input-group {
        border-radius: 5px;
      }
    }
  }
  .btn-s {
    background-color: #fff !important;
    height: 70px;
    width: 70px;
    color: #1b1077 !important;
    font-size: 28px;
    box-shadow: rgba(0, 0, 0, 0.16) 3px 3px 6px 0px;
    outline: none;
    border: none !important;
    border-radius: 50% !important;
    margin: 0.4rem;
    font-size: 28px !important;
    @media screen and (max-width: 375px) {
      height: 55px;
      width: 55px;
    }
    &.num-0 {
      width: 147px;

      border-radius: 27px !important;
      @media screen and (max-width: 375px) {
        width: 120px;
      }
    }
    &.num-back {
      background-color: #ffc781 !important;
      font-weight: bold;
      font-size: 30px;
    }
    &.num-X {
      background-color: #fea0ac !important;
    }
    &.num-equal {
      @media screen and (max-width: 375px) {
        width: 133px !important;
      }
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #b2d8d2 !important;
      border-radius: 27px !important;
      width: 160px !important;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.14) 0px 6px 6px 0px;
      &:active,
      &:focus,
      &:focus-within {
        border: none;
        box-shadow: rgba(0, 0, 0, 0.14) 0px 6px 6px 0px;
      }
      &::after {
        content: "";
        z-index: 2;
        display: block;
        position: absolute;
        width: 70px;
        height: 148px;
        right: 0;
        bottom: 0;
        @media screen and (max-width: 375px) {
          height: 120px;
          width: 55px;
        }
      }
      .shape-btn-equal::after {
        content: "";
        background-color: #b2d8d2;
        box-shadow: rgba(0, 0, 0, 0.13) 3px 0px 6px 0px;
        border-radius: 27px !important;
        z-index: -1;
        display: block;
        position: absolute;
        width: 70px;
        height: 148px;
        right: 0;
        bottom: 0;
        @media screen and (max-width: 375px) {
          height: 120px;
          width: 55px;
        }
      }
      svg {
        @media screen and (max-width: 375px) {
          width: 38px;
        }
      }
    }
  }
  .numb-pad {
    width: 333px;
    @media screen and (max-width: 375px) {
      width: 280px;
    }
  }
}
