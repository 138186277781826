@import "../node_modules/bootstrap/scss/_functions";
@import "../node_modules/bootstrap/scss/_variables";

$main-color: #ced7ee;
$main-color-dark: #ca0538;
$dark: #ca0538;
$accordion-button-active-color: $dark;
// $accordion-color: $dark;
// $accordion-bg: $dark;
$accordion-button-active-bg: tint-color($dark, 90%);
$accordion-button-focus-border-color: $dark;
$accordion-button-focus-box-shadow: $dark;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

.btn-reset {
  @media screen and (max-width: 375px) {
    padding: 0 !important;
  }
  span {
    height: 50px;
    width: 60px;
  }
}
.shadow-b {
  box-shadow: rgba(0, 0, 0, 0.16) 3px 3px 6px 0px;
}
.border-r-15 {
  border-radius: 15px;
}
.border-r-5 {
  border-radius: 5px;
}
.bg-graphic {
  z-index: -1;
  width: 109%;
  // height: 40px;
  left: -20px;
  // top: -300px;
  right: 0;
}

.nav-ico {
  height: 50px;
}
.nav-ico .d-flex {
  height: 10px;
  width: 34px;
  justify-content: center;
  align-items: center;
}

nav.navbar {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.13));
  height: 130px;
  background: url(./assets/img/header-graphics.svg);
  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 375px) {
      svg {
        width: 120px;
      }
    }
  }
}

:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;

  margin: 0 auto;
  max-width: 2400px;
  padding: 0;
}

body {
  overflow-x: hidden;
}

.c-pointer {
  cursor: pointer;
}

.btn-none {
  border: 0;
  background: 0;
}

@import "~bootstrap/scss/bootstrap";

.btn-main {
  color: white;
  background-color: $main-color-dark;
}

.btn-main:hover {
  color: white;
  background-color: $main-color;
}

.bg-main {
  background-color: $main-color-dark;
}

.table-main {
  --bs-table-color: #fff;
  --bs-table-bg: $main-color-dark;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
