.reward-card {
  background-color: white;
  width: 180px;
  height: 200px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 6px;

  .level-label {
    margin-left: -18px;
    position: absolute;
    color: white;
    padding: 5px 15px;
    box-shadow: 1px 1px 2px grey;
    border-radius: 0 5px 5px 0;
    margin-top: -5px;
  }

  &:nth-child(odd) {
    .level-label {
      background: orange;
    }
  }
  &:nth-child(even) {
    .level-label {
      background: blue;
    }
  }
}

.card-hover:hover {
  background-color: #1b1077 !important;

  span {
    display: none;
    background-color: #1b1077 !important;
    color: #1b1077 !important;
  }

  img {
    display: none;
    background-color: red;
  }

  .level-label {
    display: none;
  }

  .img_div {
    background-color: #fff;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    display: inline-block !important;
    color: "white";
    // text-align: center;
  }
}

.redeemed {
  position: fixed;
  transform: rotateZ(-45deg);
  padding: 5px;
  border-radius: 10px;
  font-size: 25px;
  background: #f4a089;
  color: #4c5340;
  border: #4c5340 2px solid;
  font-weight: 700;
  text-decoration: none solid rgb(68, 68, 68);
  text-transform: uppercase;
}
