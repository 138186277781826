#forgottenPage-div {
  background-color: #ffffff;
  .forgotten-container {
    min-height: 100vh;
  }

  .logo-img-block-sm {
    padding-top: 20px;
    text-align: center;
    .logo-img {
      width: 136px;
    }
  }

  .main-block {
    padding: 20px;
    height: 60vh;
    width: 60vw;
    max-height: 480px;
    max-width: 1024px;
  }

  .forgotten-block {
    height: 100%;
    margin-left: -1px;
    border-radius: 0 20px 20px 0;
  }

  .logo-block {
    background-color: white;
    border-radius: 20px 0 0 20px;
  }

  .logo-block img {
    width: 70%;
  }

  .forgotten-component {
    height: 100%;
  }

  @media only screen and (max-width: 992px) {
    .main-block {
      min-width: 320px;
      max-height: 480px;
    }

    .forgotten-block {
      border-radius: 20px;
    }

    .forgotten-component {
      height: 70%;
    }
  }

  @media only screen and (max-width: 576px) {
    .main-block {
      height: auto;
      min-width: 90%;
    }

    .forgotten-component {
      height: 70%;
    }

    .forgotten-btn-block {
      padding-top: 5%;
    }
  }

  @media only screen and (max-height: 600px) {
    .main-block {
      height: auto;
    }
  }
}


#forgotten-form {
  height: 100%;
  background-color: #f3f4f7;
  a {
    font-size: 0.8rem;
  }

  .form-label {
    margin: 0;
  }

  .input-group-text {
    background-color: white;
  }

  .form-label {
    font-size: 0.6rem;
  }
  .form-control {
    border-left: 0;
  }
  .input-group-text i {
    color: #abb1b9;
    width: 18px;
  }

  .password-block {
    margin-top: 10px;
  }

  .forgotten-btn-block {
    text-align: center;
    .forgotten-btn {
      width: 100%;
    }

    .login-btn {
      font-size: 0.6rem;
    }
  }
}
