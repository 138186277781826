#successResponse-block {
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.16);
  width: 100%;
  &.terugkerend {
    background-color: #ffc781;
    // ul li {
    //   min-width: 90px;
    //   // &:first-child {
    //   //   margin-right: 30px;
    //   // }
    // }
  }
  &.new {
    background-color: #fea0ac;
  }
  &.addmember {
    min-height: auto;
  }
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: block;
  background-color: #b2d8d2;
  ul {
    max-width: 310px;
    padding-right: 10px;
    padding-left: 10px;
  }
  h1 {
    font-size: 33px;
    text-align: center;
  }
  .cardBox-head__alert {
    max-width: 340px;
    margin-right: auto;
    margin-left: auto;
    h2 {
      font-size: 23px;
    }
    h5 {
      font-size: 17px;
    }
  }

  .w-full {
    width: 100%;
  }
}
