#searchBox {
  width: 100%;
  position: relative;
  box-shadow: rgb(0 0 0 / 16%) 3px 3px 6px 0px;
  border-radius: 10px;

  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 60px;
    border-radius: 10px;
  }

  .input-group {
    width: 98%;
    height: 80%;
  }

  .input-group-text.bg-transparent {
    background-color: white;
    color: black;
    height: 100%;
    border: 0px;
  }

  .content-ul {
    width: 100%;
    height: 100%;
  }

  .list-group-item {
    height: 25%;
  }

  .list-group-item:hover {
    cursor: pointer;
  }

  .select-block {
    top: -320px;
    overflow-y: overlay;
    position: absolute;
    height: 300px;
    width: 100%;
    z-index: 10;
    border: 1px solid #343a40;
  }

  .btn-basic {
    color: white;
    border-radius: 20px;
    border: 0px;
    padding: 10px;
  }

  .btn-invitation {
    background-color: #74d2df;
    @extend .btn-basic;
  }

  .btn-connect {
    background-color: #34d399;
    @extend .btn-basic;
  }
}
